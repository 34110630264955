'use strict';

jQuery(function($){
	var h = $.tb.htm,
		cookie = $.tb.cookie,
		qs = $.tb.qs(document.location.search);

	$('form[trucks]').each(function(){
		var $form = $(this),
			$sliderNav = $form.find('.legacy-slider-nav'),
			$promos = $form.find(' > comp-aside > aside > comp-cards:first-of-type > part-card'),
			blankVals = ['any','map'],
			fields = $form.find('.range-field').slider($form),
			searchInput = $form.find('input[name="textSearch"]'),
			search = searchInput.val() || '',
			dealerHighlight = $.tb.dealerHighlight,
			mapBounds = {},
			templates = $('script[type="text/template"]').toArray().reduce(function(obj,script){
				obj[script.id] = $(script).text();
				return obj;
			},{}),
			$cards = $form.find('comp-cards[template]'),
			pageSize = $cards.attr('limit') || 24,
			pageNumber = qs.pageNumber ? qs.pageNumber * 1 : 1,
			cardTemplate = templates[$cards.attr('template')],
			noResults = h('div empty-message',
				(search !== '') ? h('h3', '"' + search + '" returned zero results. Please try another search term.')
					('form action="/Trucks" method="get"',
					h('input type="search" name="' + searchInput.attr('name') + '" placeholder="Search Our Trucks" value=""')
					) : h('h3', `We don't currently have any inventory that match your search criteria.  Please contact us for available vehicles.`)
			).$,
			compareTemplates = [
				['',cardTemplate],
				['List Price','{truckPrice}'],
				['Category','{category}'],
				['Manufacturer','{truckManufacturer}'],
				['Model','{truckModel}'],
				['Year','{truckYear}'],
				['Stock Number','{truckStockNumber}'],
				['VIN','{truckVIN}'],
				['Mileage','{milesDisplay}'],
				['Engine Manufacturer','{truckEngineManufacturer}'],
				['Engine Type','{truckEngineType}'],
				['Engine Brake','{truckEngineBrake}'],
				['Horse Power','{truckHorsePowerMin}'],
				['Transmission','{truckTransmission}'],
				['Condition','{truckCondition}'],
				['Front Axle LBS','{truckFrontAxleLBS}'],
				['Rear Axle LBS','{truckRearAxleLBS}'],
				['Wheels','{truckWheels}'],
				['Wheel Base','{truckWheelBase}'],
				['Ratio','{truckRatio}'],
				['Sleeper Type','{truckSleeperType}'],
				['Sleeper Size','{truckSleeperSize}'],
				['Color','{truckColor}'],
				['Fuel','{truckFuelType}'],
                ['', '<h3>Description</h3><p>{detailedDescription}</p>'],
                ['', '{promoDisplay}']
			],
			compareList = [],
			compareTimer,
			truckImages = {},
			geo = new google.maps.Geocoder(),
			map,
			$mapEl = $form.find('map-search'),
			pins = $mapEl.find('map-pin').toArray().map(function(item){
				var $item = $(item);
				return new google.maps.Marker({
					position: {lat: parseFloat($item.attr('lat')), lng: parseFloat($item.attr('lng'))},
				});
			}),
			mapOptions = {
				zoom: 5,
				mapTypeControl: false,
				streetViewControl: false,
				scrollwheel: false,
				draggable: $(document).width() > 767 ? true : false,
				styles: $.tb.mapStyle
			},
			trucks = {};

		// Add syncPromoState function
		var syncPromoState = function () {
			var urlParams = new URLSearchParams(window.location.search);
			var promoCheckbox = $form.find('input[name="promo"]');
			promoCheckbox.prop('checked', urlParams.has('promo'));
		};

		// Call it on initial load
		syncPromoState();

		// Add pageshow handler for back button
		$(window).on('pageshow', function (event) {
			if (event.originalEvent.persisted) {
				syncPromoState();
				loadTrucks();
			}
		});
		
		$form.find('select,input').on('change', function () {
			var $field = $(this);
			switch (this.name){
				case 'centerId':
					if($field.val() === 'map'){
						$('comp-map-search').addClass('active');
					}
					else {
						$('comp-map-search, map-search').removeClass('active');
						mapBounds = {};                    
						resetFields();                    
					}
				case 'textSearch':
					search = $field.val();
			}
			
			$form.trigger('changed',{name:this.name, type:'select'});
		}).each(function(){
			if(this.name && $(this).attr('slider') === undefined){
				fields.push(this);
			}
		});
		$('comp-map-search input[type="search"]').on('keypress',function(e){
			if(e.which === 13){
				e.preventDefault();
				drawMap(this.value.trim());
			}
		});

		$form.on('click','button',function(e){
			e.preventDefault();
		});

		$form.on('click','part-card button[compare]',function(){
			var truckCard = $(this).closest('part-card');
			compareAdd(truckCard.attr('truck'),truckCard.find('img').attr('src'));		
		});
		$form.on('click','part-card button[cancel]',function(){
			var truckCard = $(this).closest('part-card');
			compareRemove(truckCard.attr('truck'));		
		});

		$form.on('changed', function (e, trigger) {               
			loadTrucks(trigger.name,trigger.type);
		});

		$form.find('[pagination]').on('click','a',function(){
			switch ($(this).attr('action')) {
				case 'next': 
					pageNumber += 1;
					break;
				case 'prev':
					pageNumber -= 1;
					break;
			}            
			loadTrucks();
			$('body > main').scrollTo($form);
		});

		// Check if geo is needed
		$form.find('[geoquery]').each(function(){
			var $field = $(this),
				key = $field.attr('data');
			
			if(!dealerHighlight){
				getGeo($field,key);			
			}
			else {
				$field.val(dealerHighlight.id);
				$form.closest('comp-container').find('[dealer-label]').text(dealerHighlight.label).closest('a').attr('href',dealerHighlight.url);
				loadTrucks(undefined,'initial');
			}
		})[0] || loadTrucks(undefined,'initial');

		function getGeo($field, key, ask) {  
			if(ask){
				navigator.geolocation.getCurrentPosition(function(position) {
					cookie.setItem('deviceLocation', JSON.stringify({
						latitude:position.coords.latitude,
						longitude:position.coords.longitude
					}));
					getNearest($field,key,position.coords);
				},function(PosError){
					console.log('PosError', PosError);
				},{enableHighAccuracy: true, maximumAge: 10000, timeout: navigator.userAgent.toLowerCase().indexOf("android") > -1 ? '15000' : '5000'});
			}
			else {  
				var dealerCard = cookie.getItem(key) !== undefined ? JSON.parse(cookie.getItem(key)) : false;               
				if (dealerCard && dealerCard.id) {    
					$field.val(dealerCard.id);
					populateNearestDealer(dealerCard);                    
					loadTrucks(undefined,'initial');
				}
				else {
					$.ajax({
						url: $.tb.geoUrl,
						dataType: 'json',
						localCache: true
					}).done(function (data) {
						getNearest($field, key, data);						
					}).fail(function(){
						loadTrucks(undefined,'initial');	
					});
				}
			}
		}

		function getNearest($field, key, data) {
			$.ajax({
				url: $field.attr('geoquery'),
				data: data,
				dataType: 'json',
				localCache: true
			}).done(function (response) {
				if (response && response.id) {
					const nearestDealerCookie = {
						id: response.id,
						ip: data.ip,
						name: response.name
					};
					cookie.setItem(key, JSON.stringify(nearestDealerCookie));
					$field.val(response.id);
					populateNearestDealer(response);
				}
				loadTrucks(undefined, 'initial');
			});
		}

		function populateNearestDealer(dealerCard) {            
			$form.find('comp-nearest h4').removeClass('loading').text(dealerCard.name);            
		}

		function reducePostData(obj, item, type) {
			var $item,
				val,
				name;

			// If item is slider
            if (Array.isArray(item.value)) {
                var urlParams = new URLSearchParams(window.location.search);
                if (type == 'initial') {
                    if (urlParams.has(item.name + 'Min')) {
                        obj[item.name + 'Min'] = urlParams.get(item.name + 'Min');
                    }
                    else {
                        obj[item.name + 'Min'] = item.value[0];
                    }
                    if (urlParams.has(item.name + 'Max')) {
                        obj[item.name + 'Max'] = urlParams.get(item.name + 'Max');
                    }
                    else {
                        obj[item.name + 'Max'] = item.value[1];
                    }
                }
                else {
                    obj[item.name + 'Min'] = item.value[0];
                    obj[item.name + 'Max'] = item.value[1];
                }
			}
			else {
				$item = $(item);
				val = $item.val();
				if (blankVals.indexOf(val) === -1) {
					name = $item.attr('name');
					if (name.indexOf('|') === -1) {
						const valueType = $item.attr('valuetype');
                        if (valueType && valueType === 'bool') {
                            obj[name] = val === 'true' || val === true ? true : false;
                        } else if (item.type == 'checkbox') {
                            if (item.checked) {
                                obj[$item.attr('name')] = val;
                            }
                        } else {
                            obj[$item.attr('name')] = val;
                        }
					}
					else {
						_.zip(name.split('|'), val.split('|')).forEach(function (set) {
							if (set[1] !== undefined) {
								obj[set[0]] = set[1];
							}
						});
					}
				}
            }
			return obj;
		}

		function getField(name) {
			for (let i = 0, l = fields.length; i < l; i++) {
				if (fields[i].name === name) {
					return fields[i];
				}
			}
			return undefined;
		}

		function redirect(trigger) {

			switch (trigger) {

				case 'centerId':
					const centerField = getField('centerId');
					if (centerField)
						location.href = `/center/${centerField.value}/trucks`;
					break;

				case 'manufacturer':
					const manufacturerField = getField('manufacturer');
					if (manufacturerField) {
						const manufacturer = manufacturerField.value === 'any'
							? ''
							: manufacturerField.value;
						location.href = `/trucks/${manufacturer}`;
					}
					break;
			}
			
		}

		function loadTrucks(trigger, type) {  
			
			if ((trigger === 'manufacturer' || trigger === 'centerId')
				&& getField('listAllManufacturers').value === 'true') {
				redirect(trigger);
				return;
			}
			
			var writePageContent = function (metaTitle, metaDescription, heading) {
				document.title = metaTitle;
				$('meta[name=description]').attr('content', metaDescription);                
				document.getElementById('heading').innerHTML = heading;
			};
			
			var postData = fields.reduce(function (obj, item) {
				const properties = reducePostData(obj, item, type);
				return properties;
			},Object.assign({
				includeStats: true,
				pageNumber: pageNumber,
				pageSize: pageSize,
				textSearch: search
			},mapBounds,JSON.parse($form.attr('params') || '{}')) || {});

			if($form.attr('home') === undefined){
				$.tb.pushQuery(postData);
			}
			
			$form.addClass('loading');
			if(type !== undefined && type !== 'initial'){
				pageNumber = 1;
			}
			
			$.ajax({
				url: $form.attr('action'),
				data: postData,
				dataType: 'json',
				localCache: true,
				method: 'GET'
			}).done(function (data) {
				var stats = data.stats || {},
					sliders = stats.sliders || {},
					total = stats.totals ? stats.totals : 0,
					promos = $promos.toArray().map(function(item){
						return $('<div>').append($(item).clone()).html();
					}); 

				if ($form.attr('home') === undefined) {
					if (data.SelectedDealer) {
						writePageContent(data.SelectedDealer.Item3, data.SelectedDealer.Item4, data.SelectedDealer.Item5);
					}
					else if (data.SelectedManufacturer) {
						writePageContent(data.SelectedManufacturer.Item2, data.SelectedManufacturer.Item3, data.SelectedManufacturer.Item4);
					}
					else if (data.SelectedPage != null) {
						writePageContent(data.SelectedPage.Item1, data.SelectedPage.Item2, data.SelectedPage.Item3);
					}					
				}

				// Update Selects
				_.each(stats,function(data,key){
					var $select = $form.find('[optionsname="'+key+'"]'),
						name = $select.attr('name'),
						current = $select.val();
					
					if ($select[0] === undefined || (trigger === name && current !== 'any')) {
						return;
					}

					$select.html(data.reduce(function (options, item) {						
						var parentDesc;						
						
						if(item.description === undefined){
							parentDesc = item.Parent.Description;
							return options + h('option value="'+parentDesc+'"',parentDesc + ' ('+item.Parent.Count+')') + item.Children.reduce(function(options,child){
								var childDesc = child.Description;
								return options + h('option value="'+parentDesc + '|' + childDesc+'"','&#160;&#160;&#160;' + childDesc + ' ('+child.Count+')');
							},'');
						}

						parentDesc = item.description.toUpperCase();
						const label = item.count > 0
							? `${parentDesc} (${item.count})`
							: parentDesc;
						
						return options + h('option value="' + parentDesc + '"', label);	

					}, $('<select>').append($select.find(blankVals.reduce(function (sel, cur) {
						return sel + (sel.length > 0 ? ',' : '' ) + ' option[value="'+cur+'"]';
					}, '')).clone()).html()));

					const compare = current.toUpperCase();

					$select.val($select.find('[value="' + compare + '"]').length > 0 ? compare : 'any');
				});
				

				// Update Summary
				$form.find('[summary]').html(total + ' Truck' + (total === 1 ? '' : 's' ));
				
				$form.find('[pagination]').html(
					h(pageNumber < 2 ? '' : 'a role="button" action="prev"', 'Previous')
					('p pagination-summary',((pageNumber * pageSize) - pageSize + 1) + ' - ' + (pageNumber * pageSize > total ? total : pageNumber * pageSize) + ' of ' + total + ' Truck' + (total === 1 ? '' : 's' ))
					(pageNumber >= Math.floor(total / pageSize) + 1 ? '' : 'a role="button" action="next"', 'Next').$
				);

				try{
					$cards.slick('unslick');
				}catch(e){}
				

				// Draw Trucks
				$cards.html(data.trucks
				// Merge in promos
				.map(function(truck,i) { 
					return i % 2 ? [truck, promos.splice(0,1).pop()] : [truck]; 
					}).reduce(function (a, b) { return a.concat(b); }, [])
					.reduce(function(html,truck){
					
					// Breaking if structure changes... 
					if(!truck){
						return html;
					}
					else if(typeof truck === 'string'){
						return html + truck;
					}
					else {
						trucks[truck.truckId] = truck;
						return html + fromTemplate(cardTemplate,truck);
					}

				},'') || noResults);
				compareDraw();

				$form.removeClass('loading');  

				setTimeout($.lazyCheck, 50);
				
				let slickOptions = {
					dots: true,
					infinite: false,
					arrows: true,
					speed: 300,
					slidesToShow: 4,
					slidesToScroll: 4,
					centerMode: false,
					variableWidth: true,

					slide: 'part-card',
					responsive: [
						{
							breakpoint: 1360,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 3
							}
						},
						{
							breakpoint: 1110,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
								centerMode: false
							}
						}
						// You can unslick at a given breakpoint now by adding:
						// settings: "unslick"
						// instead of a settings object
					]
				};

				if ($sliderNav.length > 0) {
					$sliderNav.addClass('show');
					slickOptions.prevArrow = $sliderNav.find('.btn-prev');
					slickOptions.nextArrow = $sliderNav.find('.btn-next');
                }
					

				$('comp-card-slider comp-cards').slick(slickOptions)
					.on('beforeChange', () => {
					setTimeout($.lazyCheck,10);
				});
				   
			});
		}

		function fromTemplate(template, data) {
			return template.split('{').reduce(function(str,cur){
				var pair = cur.split('}'),
					replace = pair[0].indexOf('Summary') === -1 ?  _.at(data,pair[0]) : maxLength(_.at(data,pair[0])[0] || '' , 80); // changed from 122
				return str + (cur.indexOf('}') === -1 ? cur : replace + pair[1]);
			},'');
		}

		function maxLength(text,n){
			var short = text.substr(0, n-3);
			if(text.length <= n){
				return text;
			}
			if (/^\S/.test(text.substr(n-3))){
				return short.replace(/\s+\S*$/, "") + '...';
			}
			return short + '...';
		}

		function resetFields(){
			fields.forEach(function (item) {                   
				if (item.tagName !== undefined && ['centerId', 'orderBy', 'nearestCenterId', 'textSearch', 'listAllManufacturers'].indexOf(item.name) === -1){
					$(item).val('any');
				}
			});
			pageNumber = 1;
		}

		function compareAdd(truckId,truckImage){
			truckImages[truckId] = truckImage;
			if(compareList.length < 3){
				compareList.push(truckId);
				compareDraw();
			}
			if(compareList.length > 2){
				$form.addClass('max-compare');
			}
		}

		function compareRemove(truckId){
			var $tray;
			if(truckId){
				delete truckImages[truckId];
				compareList = compareList.filter(function(item){
					return item !== truckId;
				});
				
			}
			else {
				truckImages = {};
				compareList = [];
			}
			compareDraw();
			if(compareList.length < 1){
				$tray = $('body > comp-tray');
				compareTimer = setTimeout(function(){
					$tray.removeClass('active');
					compareTimer = setTimeout(function(){
						$tray.remove();
					},1000);
				},150);
			}
			$form.removeClass('max-compare');
		}

		function compareDraw() {
			var $tray = $('body > comp-tray');
			clearTimeout(compareTimer);
			if($tray.length === 0){
				$tray = h('comp-tray',
					h('inner-content',
						h('section')
						('button compare','Compare')						
					)
					('button close')
				).$;
				$('body').append($tray);
				$tray.on('click','part-item > button',function(){
					compareRemove($(this).closest('part-item').attr('truck'));
				});
				$tray.on('click','> button[close]',function(){
					compareRemove();
				});
				$tray.on('click','button[compare]',function(){
					compareDetails();
				});
			}
			$('part-card[truck]').removeClass('compare');
			$tray.find('section').html(_.times(3).reduce(function(html,cur,index){
				var id = compareList[index],
					truckImage = id === undefined ? false : truckImages[id];
				$('part-card[truck="'+id+'"]').addClass('compare');
				return html + h('part-item ' + (truckImage ? 'truck="'+id+'" style="background-image: url('+truckImage+');"' : ''),h('button'));
			},''));
			
			if(compareList.length > 0){
				compareTimer = setTimeout(function(){
					$tray.addClass('active');
				},50);				
			}
			if(compareList.length < 2){
				$tray.find('button[compare]').attr('disabled','disabled');
			}
			else {
				$tray.find('button[compare]').removeAttr('disabled');
			}
		}

		function compareDetails(){
			var $modal = $.tb.elMaybe('body > comp-modal[compare]',function(){
					return h('comp-modal compare',
						h('header',
							h('button class-toggle="compare-active" data-target="html" close')
						)
						('inner-content')
					).$;
				},'body');

			$modal.find('> inner-content').first().html('').append(h('table',
				compareTemplates.reduce(function(rows,temp){
					return rows + h('tr',
						compareList.reduce(function (cols, truckId, index) {
							var single = temp[0] === '';
							return cols + (index === 0 ? '' : h('td gutter') ) + h(single ? '' : 'td', h('h3',temp[0]))
							('td' + (single ? ' colspan="2"' : ''),fromTemplate(temp[1],trucks[truckId]));
						},'')
					);
				},'')
			).$);

			setTimeout(function(){
				$('html').addClass('compare-active');
				$.lazyCheck(true);
			},10);
		}

		function drawMap(addr){
			$mapEl.addClass('active');
			geo.geocode( { 'address': addr}, function(results, status) {
				if (status === google.maps.GeocoderStatus.OK) {
					map = new google.maps.Map($mapEl[0], Object.assign({
						center: results[0].geometry.location
					},mapOptions));
					google.maps.event.addListener(map, 'idle', function() {
						var bounds = map.getBounds(),
							ne = bounds.getNorthEast(),
							sw = bounds.getSouthWest();
						
						mapBounds = {
							north: ne.lat(),
							south: sw.lat(),
							east: ne.lng(),
							west: sw.lng()
						};

						resetFields();                        
						loadTrucks();

						pins.forEach(function(pin){
							if(pin.getMap() !== map){
								pin.setMap(map);
							}
						});
					});
				} else {
					console.info("Geocode was not successful for the following reason: " + status);
					//inputAlert(addrEl,'Sorry, we could not find that location.');
				}
			});
		}
	});
});