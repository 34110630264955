'use strict';
jQuery(function($){
	$('comp-collapsible[dealer-list]').each(function(){
		var $list = $(this);
		$.ajax({
			url: $.tb.geoUrl,
			dataType: 'json',
			localCache: true
		}).done(function(data){
			if(data.country_code === 'CA'){
				$list.prepend($list.children().toArray().find(function(cur){
					return $(cur).find('header').text().toLowerCase() === 'canada';
				}));
			}
		});
	});
});
