'use strict';

jQuery(function ($) {

	$('.warranty-comparison-table-comp .warranty-tooltip-trigger').click(function () {	
		var tooltip_id = $(this).data('tip');
		var tooltip = $('.warranty-tooltip-' + tooltip_id);
		tooltip.addClass('tw-scale-100');
	});

	$('.warranty-comparison-table-comp .warranty-tooltip-close').click(function () {
		var tooltip_id = $(this).data('tip');
		var tooltip = $('.warranty-tooltip-' + tooltip_id);
		tooltip.removeClass('tw-scale-100');
	});

});
