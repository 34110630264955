'use strict';
(function ( $ ) {
	var iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false );
	$.tb = {
		rootURL: window.location.hostname.indexOf('.dev') === -1 ? '' : 'https://selectrucks-dev.azurewebsites.net',
		// http://demanddetroit.azurewebsites.net/umbraco/surface
		// /forms/ [Newsletter,RequestQuote,ContactDealer]
		geoUrl: 'https://api.ipstack.com/check?access_key=7652d0f71a5071a6044703dc68df0d05&output=json&legacy=1',
		iOS: iOS,
		mapURL: iOS ? 'http://maps.apple.com/?daddr=' : 'https://www.google.com/maps/?q=',
		redraw: function(delay){
			// $('main').css({
			// 	WebkitOverflowScrolling: 'auto'
			// });
			setTimeout(function(){
				$('html').hide().show(0);
			},delay || 100);

        },
        urlBuilder: function (url) {

            this.Url = url;

            this.ProcessCenter = function (filters) {
                var reUrlCenter = /\/center\/\d+\/trucks/i;
                var reMissingTruck = /\/truck\/\d{4}\/.+\/\d+\/\d+/i;

                var url = '';
                
                if (filters.centerId && !filters.fedex) {
                    if (reUrlCenter.test(this.Url)) {
                        url = this.Url.replace(reUrlCenter, '/center/' + filters.centerId + '/trucks');
                    }
                    else if (reMissingTruck.test(this.Url))
                    {                        
                        url = '/center/' + filters.centerId + '/trucks';
                        if (filters.manufacturer !== '')
                        {
                            url += '/' + filters.manufacturer;
                        }
                    }
                    else {
                        url = '/center/' + filters.centerId + this.Url;
                    }
                }
                else {
                    if (reUrlCenter.test(this.Url)) {
                        url = this.Url.replace(reUrlCenter, '/trucks');
                    }
                    else {
                        url = this.Url;
                    }
                }

                return new $.tb.urlBuilder(url);
            };
        },
        pushQuery: function (obj) {
			if(history.pushState)
            {                
				var url = window.location.protocol
					+ "//"
					+ window.location.host
                    + new this.urlBuilder(window.location.pathname).ProcessCenter(obj).Url
					+ '?' + _.map(obj,function(value,key){
						return key + '=' + encodeURIComponent(value);
					}).join('&');
				history.replaceState(obj, jQuery(document).find('title').text(), url);
			}
		},
		mapStyle: [
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#444444"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": "#f2f2f2"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [
					{
						"saturation": -100
					},
					{
						"lightness": 45
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "simplified"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"hue": "#ffcf00"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "#46BCEC"
					},
					{
						"visibility": "on"
					}
				]
			}
		],
		cookie: {
			// Simple cookie framework: https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
			getItem: function (sKey) {
				if (!sKey) { return undefined; }
				return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || undefined;
			},
			setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
				if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
				var sExpires = "";
				if (vEnd) {
					switch (vEnd.constructor) {
						case Number:
							sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
							break;
						case String:
							sExpires = "; expires=" + vEnd;
							break;
						case Date:
							sExpires = "; expires=" + vEnd.toUTCString();
							break;
					}
                }                
                document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : '') + (sPath ? "; path=" + sPath : ";path=/") + (bSecure ? "; secure" : "");
				return true;
			},
			removeItem: function (sKey, sPath, sDomain) {
				if (!this.hasItem(sKey)) { return false; }
                document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : '') + (sPath ? "; path=" + sPath : ";path=/");
				return true;
			},
			hasItem: function (sKey) {
				if (!sKey) { return false; }
				return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
			},
			keys: function () {
				var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
				for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
				return aKeys;
			}
		}
	};
}( jQuery ));