let domReady = new Promise( (resolve)=>{
    setTimeout(()=>{
        if(document.readyState === 'loading' ) {
            document.addEventListener("DOMContentLoaded", resolve);
            document.addEventListener("load", resolve);
        } else {
            resolve();
        }
    },window.CLIENT_RAZOR_ENV ? 1000 : 0);
});

export default domReady;