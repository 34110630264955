'use strict';
jQuery(function($){
	var scrolled = false;

	$('body > main').on('scroll', _.throttle(function () {		
		if($(this).scrollTop() > 50 !== scrolled){
			scrolled = !scrolled;
			$('html').toggleClass('scrolled',scrolled);
		}
	},100));

	$('html').toggleClass('search-off',$('body > main > :first-child input[type="search"]').length > 0);

	$('body > header input[type="search"]').on('keypress',function(e){
		if(e.which === 13){
			e.preventDefault();
			$(this).closest('form').submit();
		}
	});

});