'use strict';
jQuery(function($){
	$('[sticky]').each(function(){
		var $container = $(this),
			//trigger = 0,
			fixed = false,
			fixedTop = false,
			$child = $container.find(' > section'),
			$window = $(window),
			$main = $('body > main'),
			mainHeight,
			childHeight,
			containerHeight;

		function findTrigger(){
			mainHeight = $main.height();
			childHeight = $child.height();
			containerHeight = $container.height();

			//trigger = container.offset().top + ((container.height() / 2) + (buttons.height() / 2)) - $main.height();
		}
		
		function scrollCheck(){
			var //scrollTop = $main.scrollTop(),
				makeFixed = $container.offset().top - mainHeight + (containerHeight / 2) - childHeight > 0,
				makeFixedTop = !makeFixed && $container.offset().top + (containerHeight / 2) - childHeight < 0;

			if(makeFixed !== fixed){
				fixed = makeFixed;
				$container.toggleClass('stuck',fixed);
			}
			if(makeFixedTop !== fixedTop){
				fixedTop = makeFixedTop;
				$container.toggleClass('stuck-top',fixedTop);
			}
		}
		$window.on('resize',findTrigger).resize();
		$main.on('scroll',scrollCheck).scroll();
		// findTrigger();
		// scrollCheck();
	});

	$('[sticky-top]').each(function(){
		var $container = $(this),
			//trigger = 0,
			fixed = false,
			$child = $container.find(' > :first-child'),
			$window = $(window),
			$main = $('body > main'),
			offset = $container.attr('offset') || 0,
			mainTop,
			childHeight,
			containerHeight;

		function findTrigger(){
			mainTop = $main.offset().top;
			childHeight = $child.height();
			containerHeight = $container.height();
		}
		
		function scrollCheck(){
			var makeFixed = $container.offset().top - mainTop < offset;
			if(makeFixed !== fixed){
				fixed = makeFixed;
				$container.toggleClass('stuck',fixed);
				$child.css({
					top: mainTop + 'px',
					left: $container.offset().left
				});
			}

		}
		$window.on('resize',findTrigger).resize();
		$main.on('scroll',scrollCheck).scroll();
		$child.css({
			width: $child.width() + 'px'
		});
		// findTrigger();
		// scrollCheck();
	});
});