'use strict';
jQuery(function ($) {

	$('.accordion-comp .accordion-blade').click(function () {
		$(this).find('.accordion-content').toggle();
    });

});


