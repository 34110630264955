'use strict';
(function ( $ ) {
	var h = $.tb.htm;
	$.fn.scrollNav = function() {
		var $nav = this,
			overflow;
			
		function page(){
			var $scroller = overflow.$;
			if($(this).hasClass('left')){
				$scroller.animate({
					scrollLeft: $scroller.scrollLeft() - $scroller.find('>*:first').outerWidth(),
					duration: 200
				});
			}
			else {
				$scroller.animate({
					scrollLeft: $scroller.scrollLeft() + $scroller.find('>*:first').outerWidth(),
					duration: 200
				});
			}
		}

		function checkFlow(){
			$nav.toggleClass('left',overflow.left()).toggleClass('right',overflow.right());
		}

		if(!$nav.length){
			return;
		}
		
		if(!$nav.hasClass('scroll')){
			overflow = $nav.css('height',$nav.find(' > *').outerHeight()+'px')
				.addClass('scroll')
				.wrapInner(h('div wrap').$)
				.append(h('div control class="left"')('div control class="right"').$)
				.on('click','> [control]',page)
				.find('>div').on('scroll',checkFlow).overflow();
			$(window).on('resize',checkFlow);
			checkFlow();
		}
		return $nav;
	};
}( jQuery ));