'use strict';
jQuery(($) => {
	let h = $.tb.htm;
	$('comp-compare').each(function(){
		let $container = $(this);
		
		$container.find('thead td:not(:first-child)').on('click',function(e){
			let i = $(this).index() + 1;
			$container.find('td:nth-of-type('+i+')').addClass('selected');
			$container.find('td:not(:nth-of-type('+i+'))').removeClass('selected');
		}).first().click();


		$container.find('td[tooltip]:not([tooltip=""])').each(function(){
			let $cell = $(this);
			$cell.prepend(h('div data="'+$cell.attr('tooltip')+'"',h('button')).$);
		});

		$container.on('click','[tooltip] button', function() {
			var $parent = $(this).parent();
			$parent.toggleClass('tip-active');
			$container.find('.tip-active').not($parent).removeClass('tip-active');
		});
	});
});