'use strict';
jQuery(function($){
	$('[track-view]').each(function(){
		var $container = $(this),
			fixed = false,
			fixedTop = false,
			//$child = $container.find(' > section'),
			$window = $(window),
			$main = $('body > main'),
			mainHeight,
			//childHeight,
			containerHeight;

		function findTrigger(){
			mainHeight = $main.height();
			//childHeight = $child.height();
			containerHeight = $container.height();
		}
		
		function scrollCheck(){
			var //scrollTop = $main.scrollTop(),
				makeFixed = $container.offset().top - mainHeight + containerHeight > 0,
				makeFixedTop = !makeFixed && $container.offset().top + containerHeight < 0;

			if(makeFixed !== fixed){
				fixed = makeFixed;
				$container.toggleClass('off-bottom',fixed);
			}
			if(makeFixedTop !== fixedTop){
				fixedTop = makeFixedTop;
				$container.toggleClass('off-top',fixedTop);
			}
		}
		$window.on('resize',findTrigger).resize();
		$main.on('scroll',scrollCheck).scroll();
		// findTrigger();
		// scrollCheck();
	});
});