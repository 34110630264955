'use strict';
jQuery(function($){
	$('body').on('click','comp-collapsible > section > header',function(){
		var $header = $(this),
			$expand = $header.closest('section');

		if($expand.attr('collapsed') !== undefined){
			$expand.removeAttr('collapsed');
			if($('html').hasClass('touch')){
				setTimeout(function(){
					$('main').scrollTo($header);
				},250);				
			}
		}
		else {
			$expand = $('');
		}

		$header
			.closest('comp-collapsible')
			.find(' > section')
			.not($expand)
			.attr('collapsed','');
	}).find('comp-collapsible > section').attr('collapsed','');

	$('body').on('click','part-question > h2',function(){
		toggleElement(this,!this.hasAttribute('toggled'));
	});
});

function toggleElement(element,on){
    element[on ? 'setAttribute' : 'removeAttribute']('toggled','toggled');
}
