'use strict';
(function ($) {
	var controller = new ScrollMagic.Controller();
	$('comp-timeline').on('layoutComplete', function () {
		var $container = $(this);
		$container.find('[card]').each(function () {
			var $item = $(this),
				$child = $item.children();
			$child.removeClass('left');
			if (parseInt($item.css('left')) === 0) {
				$child.addClass('left');
			}
			controller.update();
		});
	}).masonry({
		itemSelector: '[card]',
		columnWidth: '[sizer]',
		percentPosition: true
	}).find('[card]').each(function () {
		var n = $(this);
		(new ScrollMagic.Scene({ triggerElement: this, triggerHook: 0.9 })
			.on("enter", function () {
				n.addClass('inview');
			})
			.addTo(controller));
		n.find('img').on('load', function () {
			n.closest('comp-timeline').masonry();
		});
	});
}(jQuery));