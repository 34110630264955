'use strict';
(function ( $ ) {
	$.fn.setClass = function(className,addRemove) {
		return this.each(function(){
			$(this).toggleClass(className,addRemove.call(this));
		});
	};

	$.fn.setSelected = function(className) {
		className = className || 'selected';
		this.addClass(className).siblings(Object.keys([].reduce.call(this,function(obj,cur){
			obj[cur.tagName.toLowerCase()] = 1;
			return obj;
		},{})).join(',')).removeClass(className);
		return this;
	};
	$.fn.setSlideOpen = function(className) {
		className = className || 'selected';
		this.slideDown().siblings(Object.keys([].reduce.call(this,function(obj,cur){
			obj[cur.tagName.toLowerCase()] = 1;
			return obj;
		},{})).join(',')).slideUp();
		return this;
	};
}( jQuery ));