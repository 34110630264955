'use strict';
(function ( $ ) {
	$.tb.elMaybe = function(selector,content,appendto) {
		var $exists = $(selector),
			$new;
		if($exists[0]){
			return $exists;
		}
		else {
			$new = content();
		}

		if(appendto !== undefined){
			$(appendto).append($new);
		}

		return $new;
	};
 
}( jQuery ));