'use strict';
(function ( $ ) {
	$.tb.htm = function(selector,content) {
		var current = [];

		function self(selector,content){
			if(typeof selector === 'string' && selector.trim() !== ''){
				current.push(['<', selector, '>',content || '','</', selector.split(' ')[0], '>'].join(''));
			}
			return self;
		}

		self.toString = function(){
			return current.join('');
		};

		Object.defineProperty(self, '$', {
			get: function() { return $(self.toString()); }
		});

		return self(selector,content);
	};
 
}( jQuery ));