'use strict';
(function ( $ ) {
	$.fn.slider = function($form) {
		return this.toArray().map(function(element){
			var $field = $(element),
				fieldName = $field.attr('name'),
				$min = $field.siblings('input[name="'+fieldName+'Min"]'),
				$max = $field.siblings('input[name="'+fieldName+'Max"]'),
				min = parseFloat($field.attr('min')),
				max = parseFloat($field.attr('max')),
				near = (max - min) / 3,
				append = $field.attr('append'),
				formatter = wNumb({
					decimals: 0,
					thousand: $field.attr('thousand'),
					prefix: $field.attr('prepend'),
					postfix: append
				}),
				value = $field.attr('value').split(',').map(function(v){return parseInt(v);}),
				field = {
					name: fieldName,
					append: append,
					value: value.map(valueNormalize.bind(null,append))
				};

			$().add($min).add($max).attr('slider','true');

			noUiSlider.create(element,{
				start: value,
				range: {
					min: min,
					max: max
				},
				margin: 0, // parseFloat($field.attr('step')),
				step: parseFloat($field.attr('step')),
				connect: true,
				tooltips: [formatter,formatter]
			}).on('change',function(value){
				field.value = value.map(valueNormalize.bind(null,append));
				$min.val(field.value[0]);
				$max.val(field.value[1]);
				if($form){
					$form.trigger('changed',{name:field.name, type:'slider'});
				}
			});

			element.noUiSlider.on('update',function(value){
				if(value[1] - value[0] < near){
					$field.addClass('crowded');
				}
				else {
					$field.removeClass('crowded');
				}
			});
			
			field.slider = element.noUiSlider;

			return field;
		});			
	};
	
	$('comp-slider slider-cards').on('init', (evt, slick) => {
		for (let i = 0, l = slick.$slides.length; i < l; i++) {
			let link = slick.$slides[i].querySelector('footer a');
			if (link && link.href !== '')
				slick.$slides[i].onclick = (evt) => {
					link.click();
                }
		}
	});

	$('comp-slider slider-cards').each(function (index) {

		const $slider = $(this);		

		const options = {
			dots: false,
			infinite: false,
			arrows: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			centerMode: false,
			variableWidth: true,
			slide: 'slider-card',
			responsive: [
				{
					breakpoint: 1360,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 1110,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 767,
					settings: {
						dots: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false
					}
				}
			]
		};

		const $nav = $slider.find('.legacy-slider-nav');

		if ($nav.length > 0) {
			$nav.addClass('show');
			options.prevArrow = $nav.find('.btn-prev');
			options.nextArrow = $nav.find('.btn-next');
        }

		$slider.slick(options).on('beforeChange', () => {
			setTimeout($.lazyCheck, 10);
		});

	});

	function valueNormalize(append,num){
		return Number(num).toFixed(0) * (append === 'k' ? 1000 : 1);
	}

}(jQuery));
