'use strict';


(function ( $ ) {

	var $main = $('body > main');

	function checkInView($container, $element, partial) {

		//Get container properties
		let cTop = 0; //$container.scrollTop()
		let cBottom = cTop + $container.height();

		//Get element properties
		let eTop = $element.offset().top - $container.offset().top;
		let eBottom = eTop + $element.height();


		//Check if in view    
		let isTotal = (eTop >= cTop && eBottom <= cBottom);
		let isPartial = partial && (
		  (eTop < cTop && eBottom > cTop) ||
		  (eBottom > cBottom && eTop < cBottom)
		);

		// console.log(eTop,isTotal,isPartial, $element);

		//Return outcome
		return  (isTotal  || isPartial);
	}

	function inView($element){
		if (!$element.is(':visible')) {
			return false;
		}
		if($element.is('part-slide')){
			let currentSlide = $element.parent().find('.slick-current');
			let visibleSlides = currentSlide.add(currentSlide.prev()).add(currentSlide.next());
			if(visibleSlides.index($element) === -1){
				return false;
			}
		}
		return checkInView($main, $element, true);
	}

	function lazyCheck(force){
		//console.log('lazy-check');
		$('[lazy-style]').each(function(){
			var $el = $(this);
			if(force || inView($el)){
				$el.attr('lazy-style').split(';').forEach((item) => {
					var st = item.split(': ');
					$el.css(st[0],st[1]).removeAttr('lazy-style');
				});				
			}
		});

		$('[lazy]').each(function(){
			var $el = $(this);
			if(force || inView($el)){
				$el.attr('lazy').split(';').forEach((item) => {
					var st = item.split(': ');
					$el.attr(st[0],st[1]).removeAttr('lazy');
				});
			}
		});
	}

	$(lazyCheck);

	$main.on('scroll',_.throttle(lazyCheck, 100));

	$(window).on('resize',_.throttle(lazyCheck, 100));

	$.lazyCheck = lazyCheck;

}( jQuery ));