'use strict';
jQuery(function($){
	// Inputmask.extendAliases({
	// 	'cur': {
	// 		alias: "numeric",
	// 		placeholder: '$',
	// 		allowPlus: false,
	// 		allowMinus: false,
	// 		autoGroup: true,
	// 		prefix: '$'
	// 	}
	// });
	$('form[calc="MonthlyPayment"]').each(function(){
		var $form = $(this),
			$result = $form.find('[result]'),
			$inputs = $form.find('input'),
			val = valFrom($inputs),
			incomplete = $result.html();
		$inputs.on('keyup',function(){
			var truckPrice = val('salesPrice',0),
				downPayment = val('downPayment',0),
				salesTax = val('salesTax',0),
				interestRate = val('interestRate',0) / 1200,
				period = val('period',0);

			$result.html(formatCur(
				(
					(
						truckPrice * (salesTax ? 1 + salesTax / 100 : 1)
					) - downPayment
				) * interestRate / (1 - (Math.pow(1 / (1 + interestRate), period)))
			, incomplete));
		});
		// console.log($form.find('input[type="number"][placeholder="$"]'));
		// $form.find('input[placeholder="$"]').inputmask('$ 999,999,999.99', { numericInput: true });
	});
	$('form[calc="PurchasingPower"]').each(function(){
		var $form = $(this),
			$result = $form.find('[result]'),
			$inputs = $form.find('input'),
			val = valFrom($inputs),
			incomplete = $result.html();
		$inputs.on('keyup',function(){
			var monthlyPayment = val('monthlyPayment',0),
				downPayment = val('downPayment',0) * 1.0,
				salesTax = val('salesTax',0),
				interestRate = val('interestRate',0) / 1200,
				period = val('period',0);

			$result.html(formatCur(
				(
					(
						monthlyPayment / (interestRate / (1 - (Math.pow(1 / (1 + interestRate), period))))
					) + downPayment
				) / (salesTax ? 1 + salesTax / 100 : 1)
			, incomplete));
		});
	});

	function valFrom($inputs){
		return function(name,def){
			var $input = $inputs.filter('[name="'+name+'"]');

			return $input[0]
				? $input.val() || def
				: def;
		};
	}

	function formatCur(val,def){
		var rounded = _.round(val,2);
		return isNaN(rounded) || rounded <= 0
			? def 
			: '$ ' + numberWithCommas(Math.floor(rounded));
	}

	function numberWithCommas(n) {
	    // format a decimal number to show commas 
	    var parts=n.toString().split(".");
	    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
	}

});